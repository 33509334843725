import React, { Component } from "react";
import { Link } from "react-router-dom";
import { old }  from '../../go-versao';
import { Navbar, Nav, NavItem, NavDropdown, MenuItem } from "react-bootstrap";

export default class HeaderView extends Component {
  constructor(props) {
    super(props);

    this.toggleUser = this.toggleUser.bind(this);
    this.setOpen = this.setOpen.bind(this);
    this.setShowEmpresa = this.setShowEmpresa.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.setShowCentral = this.setShowCentral.bind(this);
  }

  toggleUser() {
    console.log("toggleUser inside");
    if (!!this.props.toggleUser) {
      this.props.toggleUser();
    }
  }

  setOpen(nome) {
    if (!!this.props.setOpen) {
      this.props.setOpen(nome);
    }
  }

  setShowEmpresa() {
    console.log("setShowEmpresa inside");
    if (!!this.props.setShowEmpresa) {
      this.props.setShowEmpresa();
    }
  }

  handleLogout() {
    if (!!this.props.handleLogout) {
      this.props.handleLogout();
    }
  }

  setShowCentral() {
    if (!!this.props.setShowCentral) {
      this.props.setShowCentral();
    }
  }

  // 1	Administrador
  // 2	Gestor
  // 3	Gestor Aprovador
  // 4	Contas a Pagar
  // 6	Sub-Gestor
  // 5	Usuário Solicitante
  // 7	Usuário

  render() {
    let iconcentral = this.props.state.selected.appicon;
    let iconEmpresa = this.props.state.empresa.appicon;
    var isAuthenticated = this.props.state.isAuthenticated;
    var perfil = this.props.state.perfilId;
    var selected = this.props.state.selected;
    var userData = this.props.state.userData;
    var empresa = this.props.viewModel.state.empresa;

    if (!isAuthenticated) {
      return <></>;
    } else {
      if (!old) {
      return (
        <Navbar
          sticky="top"
          onSelect={(x, y) => {
            console.log(x);
            if (x === 9.1) {
              this.setShowEmpresa();
            } else if (x === 9.4) {
              this.handleLogout();
            }
          }}
        >
          <Navbar.Header>
            <Navbar.Brand as={Link} to="/">
            <div style={{
                          backgroundImage: `url(${iconcentral})`,
                          backgroundSize: 'contain',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                          width: 90,
                          height: 90,
                        }}>
                          </div>
            </Navbar.Brand>
          </Navbar.Header>

          <Navbar.Collapse>
            <Nav>
              {(perfil === 2 || perfil === 1) && (
                <NavItem eventKey={1} href="/painel">
                  Painel
                </NavItem>
              )}
              <NavItem eventKey={2} href="/solicitar">
                Nova Solicitação
              </NavItem>
              {(perfil === 2 ||
                perfil === 1 ||
                perfil === 5 ||
                perfil === 7 ||
                perfil === 6 ||
                perfil === 4) && (
                <NavDropdown
                  eventKey={3}
                  title="Solicitações"
                  id="basic-nav-dropdown"
                >
                  <MenuItem eventKey={3.1} href="/corrida-andamento">
                    Em Andamento
                  </MenuItem>
                  <MenuItem eventKey={3.2} href="/corridas-agendadas">
                    Agendadas
                  </MenuItem>
                  <MenuItem divider />
                  <MenuItem eventKey={3.3} href="/corridas-concluidas">
                    Concluídas
                  </MenuItem>
                  <MenuItem eventKey={3.4} href="/corridas-canceladas">
                    Canceladas
                  </MenuItem>
                </NavDropdown>
              )}
              {(perfil === 2 || perfil === 1 || perfil === 4) && (
                <NavDropdown
                  eventKey={5}
                  title="Gestão"
                  id="basic-nav-dropdown"
                >
                  <MenuItem eventKey={5.1} href="/faturas">
                    Faturas
                  </MenuItem>
                  <MenuItem divider />
                  <MenuItem eventKey={5.2} href="/departamentos">
                    Departamentos
                  </MenuItem>
                  <MenuItem divider />
                  <MenuItem eventKey={5.4} href="/pagamentos">
                    Tipo de pagamento
                  </MenuItem>
                  <MenuItem eventKey={5.5} href="/usuarios">
                    Usuários
                  </MenuItem>
                  <MenuItem divider />
                  <MenuItem eventKey={5.2} href="/parceria">
                    Parcerias
                  </MenuItem>
                </NavDropdown>
              )}
              {(perfil === 2 ||
                perfil === 1 ||
                perfil === 6 ||
                perfil === 4) && (
                <NavDropdown
                  eventKey={6}
                  title="Relatórios"
                  id="basic-nav-dropdown"
                >
                  <MenuItem eventKey={6.1} href="/voucher-faturar2">
                    Vouchers à Faturar
                  </MenuItem>
                  <MenuItem eventKey={6.2} href="/voucher-faturados2">
                    Vouchers Faturados
                  </MenuItem>
                  <MenuItem eventKey={6.3} href="/voucher-cancelados2">
                    Vouchers Cancelados
                  </MenuItem>
                  <MenuItem divider />
                  <MenuItem eventKey={6.4} href="/empregados2">
                    Funcionarios
                  </MenuItem>
                  <MenuItem eventKey={6.5} href="/centro-de-custo2">
                    Centro de custo
                  </MenuItem>
                </NavDropdown>
              )}
            </Nav>
            <Nav pullRight>
              <NavDropdown
              className="my-dropdown"
                eventKey={0}
                title={
                  userData.nomecompleto ? userData.nomecompleto : userData.nome
                }
                id="basic-nav-dropdown"
              >
                <MenuItem eventKey={9.1} href="#">
                  {!!selected.id && selected.nomefantasia}
                </MenuItem>
                <MenuItem eventKey={9.2} href="/minha-conta">
                  Minha Conta
                </MenuItem>
                <MenuItem eventKey={9.3} href="/favoritos">
                  Favoritos
                </MenuItem>
                <MenuItem divider />
                <MenuItem eventKey={9.4} href="#">
                  Sair
                </MenuItem>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      );
              } else {
                return (
                  <Navbar
                    sticky="top"
                    onSelect={(x, y) => {
                      console.log(x);
                      if (x === 9.1) {
                        this.setShowEmpresa();
                      } else if (x === 9.4) {
                        this.handleLogout();
                      }
                    }}
                  >
                    <Navbar.Header>
                      <Navbar.Brand as={Link} to="/">
                        <div style={{
                          backgroundImage: `url(${iconcentral})`,
                          backgroundSize: 'contain',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                          width: 90,
                          height: 90,
                        }}>
                          </div>
                      </Navbar.Brand>
                    </Navbar.Header>
          
                    <Navbar.Collapse>
                      <Nav>
                        {(perfil === 2 || perfil === 1) && (
                          <NavItem eventKey={1} href="/painel">
                            Painel
                          </NavItem>
                        )}
                        <NavItem eventKey={2} href="/solicitar">
                          Nova Solicitação
                        </NavItem>
                        {(perfil === 2 ||
                          perfil === 1 ||
                          perfil === 5 ||
                          perfil === 7 ||
                          perfil === 6 ||
                          perfil === 4) && (
                          <NavDropdown
                            eventKey={3}
                            title="Solicitações"
                            id="basic-nav-dropdown"
                          >
                            <MenuItem eventKey={3.1} href="/corrida-andamento">
                              Em Andamento
                            </MenuItem>
                            <MenuItem eventKey={3.2} href="/corridas-agendadas">
                              Agendadas
                            </MenuItem>
                            <MenuItem divider />
                            <MenuItem eventKey={3.3} href="/corridas-concluidas">
                              Concluídas
                            </MenuItem>
                            <MenuItem eventKey={3.4} href="/corridas-canceladas">
                              Canceladas
                            </MenuItem>
                          </NavDropdown>
                        )}
                        {(perfil === 2 || perfil === 1 || perfil === 4) && (
                          <NavDropdown
                            eventKey={5}
                            title="Gestão"
                            id="basic-nav-dropdown"
                          >
                            <MenuItem eventKey={5.1} href="/faturas">
                              Faturas
                            </MenuItem>
                            <MenuItem divider />
                            <MenuItem eventKey={5.2} href="/departamentos">
                              Departamentos
                            </MenuItem>
                            <MenuItem divider />
                            <MenuItem eventKey={5.4} href="/pagamentos">
                              Tipo de pagamento
                            </MenuItem>
                            <MenuItem eventKey={5.5} href="/usuarios">
                              Usuários
                            </MenuItem>
                          </NavDropdown>
                        )}
                        {(perfil === 2 ||
                          perfil === 1 ||
                          perfil === 6 ||
                          perfil === 4) && (
                          <NavDropdown
                            eventKey={6}
                            title="Relatórios"
                            id="basic-nav-dropdown"
                          >
                            <MenuItem eventKey={6.1} href="/voucher-faturar2">
                              Vouchers à Faturar
                            </MenuItem>
                            <MenuItem eventKey={6.2} href="/voucher-faturados2">
                              Vouchers Faturados
                            </MenuItem>
                            <MenuItem eventKey={6.3} href="/voucher-cancelados2">
                              Vouchers Cancelados
                            </MenuItem>
                            <MenuItem divider />
                            <MenuItem eventKey={6.4} href="/empregados2">
                              Funcionarios
                            </MenuItem>
                            <MenuItem eventKey={6.5} href="/centro-de-custo2">
                              Centro de custo
                            </MenuItem>
                          </NavDropdown>
                        )}
                      </Nav>
                      <Nav pullRight>
                        <NavDropdown
                        className="my-dropdown"
                          eventKey={0}
                          title={
                            userData.nomecompleto ? userData.nomecompleto : userData.nome
                          }
                          id="basic-nav-dropdown"
                        >
                          <MenuItem eventKey={9.1} href="#">
                            {!!selected.id && selected.nomefantasia}-{!!empresa && empresa.razaosocial}
                          </MenuItem>
                          <MenuItem eventKey={9.2} href="/minha-conta">
                            Minha Conta
                          </MenuItem>
                          <MenuItem eventKey={9.3} href="/favoritos">
                            Favoritos
                          </MenuItem>
                          <MenuItem divider />
                          <MenuItem eventKey={9.4} href="#">
                            Sair
                          </MenuItem>
                        </NavDropdown>
                          {!!iconEmpresa &&                       <Navbar.Brand as={Link} to="/">
                        <img
                          src={iconEmpresa}
                          width={90}
                          height={90}
                          className="d-inline-block align-top"
                          alt="Logo"
                        />
                      </Navbar.Brand>}
                      </Nav>
                      </Navbar.Collapse>
                      
                  </Navbar>
                );
              }
    }
  }
}
